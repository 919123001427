import React from 'react';
import logo from './logo.svg';
import './App.css';
import LandingPage from './components/Landing/LandingPage';
import Sidebar from './components/Sidebar/Sidebar';

function App() {
  return (
    <>
      <div className="App">
          <LandingPage mobile={false}/>
          <Sidebar />
      </div>
      <div className="AppReverse">
        {/* <Sidebar /> */}
        <LandingPage mobile={true}/>
      </div>
    </>
  );
}

export default App;

import { Routes, Route } from 'react-router-dom';
import LandingPage from './components/Landing/LandingPage';
import ThankYou from './components/ThankYou/ThankYou';
import App from './App';


type Props = {};

const Content = (props: Props) => {
  return (
    <>
      <div>
            <Routes>
              <Route path="/" element={<App />} />
              <Route path="/landing" element={<App />} />
              <Route path="/thankYou" element={<ThankYou />} />
            </Routes>
      </div>     
    </>
  );
};

export default Content;

import React, { useEffect, useState } from 'react'
import '../../index.css';
import Sidebar from '../Sidebar/Sidebar';
import * as Icon from 'react-icons/fa'; 

type LandingPageProps = {
  mobile?: boolean
}

const LandingPage = (props: LandingPageProps) => {
  
  useEffect(() => {

    const handleScroll = () => {
      const choiceSection = document.querySelector('.choiceSection');
      const finalSection = document.querySelector('.finalSection');

      if (choiceSection) {
        const rect = choiceSection.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        if (rect.top <= windowHeight) {
          choiceSection.classList.add('sliding-on-l');
        } else {
          choiceSection.classList.remove('sliding-on-l');
        }
      }

      if (finalSection) {
        const rect = finalSection.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        if (rect.top <= windowHeight) {
          finalSection.classList.add('sliding-on-r');
        } else {
          finalSection.classList.remove('sliding-on-r');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('load', handleScroll)

    return () => {
      window.removeEventListener('load', handleScroll)
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <div className='MainPage'>
      <div style={{width: '90%', borderBottom: '1px solid #ccc', textAlign: 'center', padding: '1.5rem'}}>
        <img src='/logo.png' className='Logo'/>
      </div>
      <div className='MainContent'>
        <h1 className='fancyTitle' style={{color: '#0e4a77'}}>Sviluppo software su misura, per ogni esigenza!</h1>
        <h2 className='fancySubtitle'>Il tuo progetto, il nostro software</h2>
        <br/>
        <p className='baseText'>Sviluppiamo <b>software su misura</b> personalizzati con l'obiettivo di <b>conciliare la tua visione con  la nostra soluzione più adatta.</b><br/>
        Cosa rende unica la nostra offerta? <b>Mettiamo te al centro di tutto</b>, ascoltando le tue idee, i tuoi propositi e le tue sfide.<br/>
        In fine, con la nostra esperienza e competenza, <u>creiamo un software digitale che si adatti perfettamente alle tue esigenze!</u></p>
        <p className='baseText'>Il nostro team di sviluppatori software si occupa di realizzare software <b>.NET Core</b> con <b>Angular</b> o <b>React</b>, ma anche microservizi, che permettono di ottenere un'applicazione scalabile e accelerare l'immissione di nuove funzionalità.</p>
        <hr/>

        <div>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Icon.FaLaptopCode style={{color: '#0e4a77', fontSize: '3.25rem'}}/>
            <h2 className='fancyTitle' style={{color: '#0e4a77', fontSize: '2.5rem'}}>&nbsp;I nostri servizi</h2>
          </div>
          <ul className='service-list'>
            <li className='service-item'>
              <Icon.FaCheckCircle className="service-icon" />
              <span>Software di CRM per la gestione dei clienti</span>
            </li>
            <li className='service-item'>
              <Icon.FaCheckCircle className="service-icon" />
              <span>Sviluppo di gestionali ERP e Software di gestione aziendale, che consente la gestione ed automatizzazione dei processi aziendali</span>
              </li>
            <li className='service-item'>
              <Icon.FaCheckCircle className="service-icon" />
              <span>Gestione documentale, una soluzione software utilizzata per centralizzare e condividere i documenti in modo sicuro</span>
              </li>
            <li className='service-item'>
              <Icon.FaCheckCircle className="service-icon" />
              <span>Software di gestione appuntamenti, flessibile e adattabile a qualsiasi ambito lavorativo</span>
              </li>
            <li className='service-item'>
              <Icon.FaCheckCircle className="service-icon" />
              <span>Applicazioni scolastiche, utili per studenti, insegnanti e segreteria</span>
            </li>
            <li className='service-item'>
              <Icon.FaCheckCircle className="service-icon" />
              <span>Soluzioni software Web-Based ospitate sui nostri server oppure in cloud</span>
            </li>
            <li className='service-item'>
              <Icon.FaCheckCircle className="service-icon" />
              <span>Servizi su misura che comprendono lo sviluppo del Back-End e del Front-End, nonché del Project Management</span>
              </li>
          </ul>
        </div>

        <br />

        {/* SLIDING SECTION */}
        <div className='sliding-off-l choiceSection'>
          <h2 className='fancyTitle' style={{color: '#fff', fontSize: '1.75rem'}}>Perché sceglierci?</h2>
          <p>Se stai affrontando <b>sfide aziendali importanti</b> e specifiche che il tuo attuale software non riesce a risolvere, se desideri unire i tuoi reparti o <b>creare sinergie</b> tra di essi, 
            oppure se il software che stai utilizzando non riesce a soddisfare le tue esigenze, allora potremmo essere noi l'aiuto che cerchi!</p>
            <hr/>
            <h4>Scegliere un software personalizzato significa:</h4>
          <ul className='service-list'>
            <li className='service-item'>
              <Icon.FaCheckCircle className="service-icon-light" />
              <span><b>Ottimizzare i processi</b> con maggiore efficienza ed efficacia</span>
            </li>
            <li className='service-item'>
              <Icon.FaCheckCircle className="service-icon-light" />
              <span><b>Potenziare le prestazioni</b> del tuo team di lavoro</span> 
            </li>
            <li className='service-item'>
              <Icon.FaCheckCircle className="service-icon-light" />
              <span>Rafforzare la <b>sicurezza</b> dei tuoi dati aziendali</span>
            </li>
            <li className='service-item'>
              <Icon.FaCheckCircle className="service-icon-light" />
              <span><b>Integrare</b> altre soluzioni in un unico canale</span>
            </li>
            <li className='service-item'>
              <Icon.FaCheckCircle className="service-icon-light" />
              <span>Personalizzare il design in linea con la tua <b>immagine aziendale</b></span>
            </li>
            <li className='service-item'>
              <Icon.FaCheckCircle className="service-icon-light" />
              <span>Raggiungere un livello di indipendenza con un <b>software esclusivamente tuo!</b></span>
            </li>
          </ul>
        </div>


        { props.mobile ?
        <div style={{marginRight: '-5.5%', marginLeft: '-5.5%'}}>
          <Sidebar />
          <br/>
        </div>
          :
          <br />
        }

        <div className='sliding-off-r finalSection' style={{display: 'flex'}}>
          <Icon.FaLightbulb className='lightbulb'/>
          <h2 style={{color: '#0e4a77'}}>
          Noi di 3E Informatica siamo qui per te, e ti offriremo una consulenza completa, dall'ideazione all'implementazione, 
          convergendo le tue richieste in un unico approccio affidabile e conforme alle necessità della tua azienda!
          </h2>
        </div>
      </div>
      <br/>
      <br/>
      <br/>

      <footer className='footer'>

          <div className='footerRow' style={{borderBottom: '1px solid #68BD45'}}>
            <img src='/logo2.png'/>
            <div className='socialsContainer'>
              <a href="https://3einformatica.com/"><Icon.FaGlobe className='social'></Icon.FaGlobe></a>
              <a href="https://www.facebook.com/3EInformatica/?locale=it_IT"><Icon.FaFacebook className='social'></Icon.FaFacebook></a>
              <a href='https://it.linkedin.com/company/3einformatica'><Icon.FaLinkedin className='social'></Icon.FaLinkedin></a>
              <a href='https://www.instagram.com/3einformaticasrl/'><Icon.FaInstagram className='social'></Icon.FaInstagram></a>
            </div>
          </div>


          <div className='footerContent'>

            <div className='footerCol'>
              <h3 style={{margin: 0}}>Servizi:</h3>
              <a href="https://3einformatica.com/siti-web/" target='_blank'>I nostri siti</a>
              <a href="https://3einformatica.com/progettazione-e-sviluppo-sw/" target='_blank'>Progettazione e sviluppo SW</a>
              <a href="https://3einformatica.com/salfiorapp/" target='_blank'>SalfiorApp</a>
            </div>
            <div className='footerCol'>
              <h3 style={{margin: 0}}>Contatti:</h3>
              <a href="mailto:info@3einformatica.it">Info@3einformatica.it</a>
              <a href="https://3einformatica.com/contatti/" target='_blank'>Pagina contatti</a>
              <a href="https://3einformatica.com/team/" target='_blank'>Lavora con noi</a>
            </div>
            <div className='footerCol'>
              <h3 style={{margin: 0}}>Dove siamo?:</h3>
              <iframe className='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2817.7722000334297!2d7.670969375737138!3d45.07013195964605!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47886d0d78b9934b%3A0x100efab33fbf13bc!2sC.so%20Galileo%20Ferraris%2C%202%2C%2010121%20Torino%20TO!5e0!3m2!1sit!2sit!4v1696500225173!5m2!1sit!2sit" width="600" height="450" style={{border:0}} loading="lazy" ></iframe>
            </div>

          </div>
          <div className='footerRow' style={{borderTop: '1px solid #68BD45'}}>
              <i>3E Informatica ©. All rights reserved.</i>
              <i>Partita Iva IT11244340011</i>
            </div>
        </footer>
    </div>
  )
}

export default LandingPage
import React from 'react'
import '../../index.css'
import * as Icon from 'react-icons/fa'; 


type Props = {}

const ThankYou = (props: Props) => {
  return (
    <>
      <div className='thankYouContainer'>
        <div className="thankYouTop">
          <span className='thankYouCredit'>Designed by 3Einformatica®</span>
          <img src='/logo.png' className='thankYouLogo'/>
        </div>
      

        <div className='thankYouContent'>
          <div>
            <h1 className='thankYouTitle'>Grazie per averci contattato!</h1>
            <h2 className='thankYouSubtitle'>La ricontatteremo il più presto possibile</h2>
          </div>
          <img src='/logo.png' className='thankYouLogoMobile'/>
        </div>

        <div className='thankYouLinks'>
          <a className='thankYouIcon' href="https://3einformatica.com/"><Icon.FaGlobe ></Icon.FaGlobe></a>
          <a className='thankYouIcon' href="https://www.facebook.com/3EInformatica/?locale=it_IT"><Icon.FaFacebook ></Icon.FaFacebook></a>
          <a className='thankYouIcon' href='https://it.linkedin.com/company/3einformatica'><Icon.FaLinkedin ></Icon.FaLinkedin></a>
          <a className='thankYouIcon' href='https://www.instagram.com/3einformaticasrl/'><Icon.FaInstagram ></Icon.FaInstagram></a>
        </div>
      </div>
    </>
  )
}

export default ThankYou
import React, { useState } from 'react'

type SidebarProps = {
}

const Sidebar = (props: SidebarProps) => {
  return (
    <div className='Sidebar' >
      
       <h1 className='fancyTitle' style={{lineHeight: '2.5rem'}}>
        Contattaci oggi stesso per qualsiasi informazione!
       </h1>
       
       <form action="sendMail.php">
          {/* <div className='formInputRow'>
            <div className='formInputContainer'>
              <label htmlFor="Nome">Nome *</label>
              <input type="text" name="Nome" id="Nome" placeholder="Nome"></input>
            </div>
            <div className='formInputContainer'>       
              <label htmlFor="Cognome">Cognome *</label>
              <input type="text" name="Cognome" id="Cognome" placeholder="Cognome"></input>
            </div>
          </div> */}
          <div className='formInputContainer'>
            <label htmlFor="RagioneSociale">Ragione sociale *</label>
            <input type="text" name="RagioneSociale" id="RagioneSociale" placeholder="Ragione sociale" required></input>
          </div>

          <div className='formInputRow'>
            <div className='formInputContainer'>
              <label htmlFor="Email">Email *</label>
              <input type="email" name="Email" id="Email" placeholder="Email" required></input>
            </div>
            <div className='formInputContainer'>       
              <label htmlFor="Telefono">Telefono *</label>
              <input type="tel" pattern="^[0-9]+$" name="Telefono" id="Telefono" placeholder="Telefono" required></input>
            </div>
          </div>

          <div className='formInputContainer'>
            <label htmlFor="Messaggio">Messaggio *</label>
            <textarea name="Messaggio" id="Messaggio" placeholder="Il tuo messaggio" style={{minHeight: '6rem'}} required></textarea>
          </div>

          <div className='formInputContainer'>
            <div style={{display: 'flex', textAlign: 'left', gap: '0.5rem', marginTop: '-0.75rem'}}>
              <input type='checkbox' name="Messaggio" id="Messaggio" placeholder="Il tuo messaggio" required/>
              <p>Acconsento a ricevere comunicazioni commerciali e promozionali relative ai nostri servizi</p>
            </div>
          </div>

          <div style={{alignSelf: 'end'}}>
            <button type='submit' className='submitButton'>Invia messaggio</button>
          </div>
       </form>
       
    </div>
  )
}

export default Sidebar